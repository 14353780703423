<template>
  <div>
    找不到页面哦
    <span >{{ time }}</span>
    秒后返回搜索页面
    <button @click="goPage" >返回搜索</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 3,
      countdown: null,
    }
  },
  mounted() {
    this.countdown = setInterval(()=> {
      this.time--
      if(this.time == 0) {
        this.goPage();
      }
    },1000)
    // setTimeout(()=> {
    //   this.goPage();
    // },3000)
  },
  methods: {
    goPage() {
      clearInterval(this.countdown)
      this.$router.push({ name: 'SearchPage' })
    },
  }
}
</script>

<style lang="scss" scoped>
.abc {
  
}

</style>